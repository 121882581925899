export const docsConfig = {
  sidebarNav: [
    {
      title: "Getting Started",
      items: [
        {
          title: "Introduction",
          href: "/developers",
          items: [],
        },
        {
          title: "Enpoints",
          href: "/developers?page=endpoints",
          items: [],
        },
        {
          title: "Changelog",
          href: "/developers?page=changelog",
          items: [],
        },
      ],
    },
    {
      title: "Authentication",
      items: [
        {
          title: "Overview",
          href: "/developers?folder=user&page=readme",
          items: [],
        },
        {
          title: "Authenticate",
          href: "/developers?folder=user&page=authenticate",
          items: [],
        },
        {
          title: "Refresh Token",
          href: "/developers?folder=user&page=refreshToken",
          items: [],
        },
      ],
    },
    {
      title: "KYC",
      items: [
        {
          title: "Overview",
          href: "/developers?folder=kyc&page=readme",
          items: [],
        },
        {
          title: "Get Questions",
          href: "/developers?folder=kyc&page=getKYCQuetions",
          items: [],
        },
        {
          title: "Add Answer",
          href: "/developers?folder=kyc&page=addAnswers",
          items: [],
        },
        {
          title: "Get Submitted Answer",
          href: "/developers?folder=kyc&page=getSubmitedAnswers",
          items: [],
        },
        {
          title: "Get Users KYC",
          href: "/developers?folder=kyc&page=getAllUserKYC",
          items: [],
        },
        {
          title: "Upload File",
          href: "/developers?folder=kyc&page=upload",
          items: [],
        },
        {
          title: "Get KYC Status",
          href: "/developers?folder=kyc&page=kycStatus",
          items: [],
        },
        {
          title: "Submit KYC",
          href: "/developers?folder=kyc&page=submit",
          items: [],
        },
        {
          title: "Download Document",
          href: "/developers?folder=kyc&page=downloadKYCDocument",
          items: [],
        },
        {
          title: "Delect Document",
          href: "/developers?folder=kyc&page=deleteKYCDocument",
          items: [],
        },
        // {
        //   title: "User KYC ",
        //   label: "Deprecated",
        //   href: "/developers?folder=kyc&page=getUserKYC",
        //   items: [],
        // },
      ],
    },
    {
      title: "Securities",
      items: [
        {
          title: "Get Available Securities",
          href: "/developers?folder=securities&page=listSecurities",
          items: [],
        },
        {
          title: "Get Fund Particular",
          href: "/developers?folder=securities&page=getSecurity",
          items: [],
        },
      ],
    },
    {
      title: "Positions",
      items: [
        {
          title: "Get Positions By Asset Class",
          href: "/developers?folder=positions&page=getAssetClassPosition",
          items: [],
        },
        {
          title: "Get Positions By Security ID",
          href: "/developers?folder=positions&page=getSecurityPosition",
          items: [],
        },
      ],
    },
    {
      title: "Order",
      items: [
        {
          title: "Create Order Request",
          href: "/developers?folder=order&page=createOrderRequest",
          items: [],
        },
        {
          title: "Order Offer",
          href: "/developers?folder=order&page=orderOffer",
          items: [],
        },
        {
          title: "Place Order",
          href: "/developers?folder=order&page=placeOrder",
          items: [],
        },
        {
          title: "Get All Orders",
          href: "/developers?folder=order&page=getAllOrders",
          items: [],
        },
        {
          title: "Cancel Order",
          href: "/developers?folder=order&page=cancelOrder",
          items: [],
        },
      ],
    },
    {
      title: "Transactions",
      items: [
        {
          title: "Get Transactions",
          href: "/developers?folder=Transactions&page=getTransactions",
          items: [],
        },
      ],
    },
    {
      title: "Collection",
      items: [
        {
          title: "Create Collection Request",
          href: "/developers?folder=collection&page=createCollectionRequest",
          items: [],
        },
        {
          title: "Get Collection Request",
          href: "/developers?folder=collection&page=getCollectionRequest",
          items: [],
        },
        // {
        //   title: "Check Payment Status",
        //   label: "Deprecated",
        //   href: "/developers?folder=collection&page=checkPaymentStatus",
        //   items: [],
        // },
      ],
    },
    // {
    //   title: "Withdrawal",
    //   items: [
    //     {
    //       title: "Create Withdrawal Request",
    //       href: "/developers?folder=withdrawal&page=createWithdrawalRequest",
    //       items: [],
    //     },
    //   ],
    // },
    {
      title: "Prices",
      items: [
        {
          title: "Performance Chart",
          href: "/developers?folder=prices&page=performanceChart",
          items: [],
        },
      ],
    },
  ],
};
