import React, { useState, useRef } from "react";
import "../Holding/holdings.scss";
// import { Sparklines, SparklinesLine } from "react-sparklines";

function HoldingTable() {
  return (
    <React.Fragment>
      <table class="darkTable">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Security Name</th>
            <th className="sector">Sector</th>
            <th className="weight">Weight (%)</th>
            <th className="sparkline">Sparkline</th>
          </tr>
        </thead>

        <tbody>
          {Array.from({ length: 5 }).map((x, i) => {
            return (
              <tr key={i}>
                <td>MSFT.US</td>
                <td>Microsoft Corp</td>
                <td className="sector">Technology</td>
                <td className="weight">8.6%</td>
                <td className="sparkline">
                  <Chart />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default HoldingTable;

const Chart = () => {
  return (
    <div>
      {/* <Sparklines
        data={[5, 10, 5, 20, 8, 15, 60]}
        // limit={5}
        width={100}
        height={20}
        margin={5}
      >
        <SparklinesLine color="#F4BD12" />
      </Sparklines> */}
    </div>
  );
};
