import React, { useEffect, useState } from "react";
import "../create_account.scss";
import { Link, useNavigate } from "react-router-dom";

function InitPage({ setPage }) {
  const navigate = useNavigate();
  const [fadeInAni, setFadeInAni] = useState("fade");

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  return (
    <React.Fragment>
      <main className={`init_page ${fadeInAni}`}>
        <h1>Register as</h1>
        <div className="init_page_container">
          <Link
            style={{
              textDecoration: "none",
            }}
            to={"https://linktr.ee/tech.blackstargroup"}
          >
            <div className="init_page_card card_1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-user"
              >
                <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                <circle cx="12" cy="7" r="4" />
              </svg>
              <h1>Individual</h1>
            </div>
          </Link>
          <div
            onClick={() => {
              setPage(1);
            }}
            className="init_page_card card_2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-landmark"
            >
              <line x1="3" x2="21" y1="22" y2="22" />
              <line x1="6" x2="6" y1="18" y2="11" />
              <line x1="10" x2="10" y1="18" y2="11" />
              <line x1="14" x2="14" y1="18" y2="11" />
              <line x1="18" x2="18" y1="18" y2="11" />
              <polygon points="12 2 20 7 4 7" />
            </svg>
            <h1>institution</h1>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default InitPage;
