import React, { useEffect, useState } from "react";
import "../InvestmentGoal/investment_goal.scss";
import ChevronRight from "../../Assets/right-chevron.png";
import { Link, useNavigate } from "react-router-dom";
import gt from "../../Assets/Icon/gt.svg";

const INVESTMENT_GOAL = [
  {
    name: "Cash Plus",
    description:
      "Use short-term investment strategies to boost return maintain liquidity",
    more: "",
  },
  {
    name: "Global Tech",
    description: "Exposure to the top technology stocks in the world.",
    more: "",
  },
  {
    name: "Global Equity",
    description: "Get access to the top 500 companies in the world.",
    more: "",
  },
  {
    name: "Balanced Growth",
    description:
      "Get access to a balance between global equity and quality fixed income assets.",
    more: "",
  },
  {
    name: "DigiSave",
    description:
      "Investments that provides study growth while preserving capital.",
    more: "",
  },
];

function InvestmentGoal() {
  const navigate = useNavigate();
  const [fadeInAni, setFadeInAni] = useState("fade");

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  return (
    <React.Fragment>
      <div className={`investment_goals mt-4 ${fadeInAni}`}>
        <h1>Explore Our Mutual Funds</h1>
        <div className="methodology_head d-flex">
          <p
            className="m-0 align-self-center"
            onClick={() => {
              navigate("/our_methodology");
            }}
          >
            Investment Focus
          </p>
          {/*<img className="mt-1 align-self-center ms-2" src={ChevronRight} alt="chevron-right"/>*/}
        </div>
        <div className="investment_goals_card_wrapper d-flex flex-wrap text-start text-white mt-5">
          {INVESTMENT_GOAL?.map((data, index) => {
            return (
              <div className="investment_goals_card" key={index}>
                <h3 className="mb-3 name">{data.name}</h3>
                <p className="m-0 mb-3">{data.description}</p>
                <Link className="readmore" to={`/ifunds?menu=${index + 1}`}>
                  Read more
                  <img
                    src={gt}
                    alt="gt icon"
                    className="gticon"
                    width="6"
                    height="11"
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default InvestmentGoal;
