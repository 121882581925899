import axios from 'axios';
const BASE_URL = 'https://api.gnii.ai/api/public/funds';

const GetApi = (tag = '') => {
  return axios
    .get(BASE_URL + tag)
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch((error) => {
      return error.response;
    });
};

// const PostApi = (tag = '', reqBody) => {
//     return axios
//         .post(BASE_URL + tag, reqBody)
//         .then((data) => {
//             if (data.status === 200) {
//                 return data;
//             } else {
//                 return data;
//             }
//         })
//         .catch((error) => {
//             return error.response;
//         });
// };

// const DeleteApi = (tag = '') => {
//   return axios
//     .delete(BASE_URL + tag)
//     .then((data) => {
//       if (data.status === 200) {
//         return data;
//       } else {
//         return data;
//       }
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };
//
// const PutApi = (tag = '', reqBody, isHeader) => {
//   const headers = {
//     'Content-Type': 'image/jpeg',
//   };
//   return axios
//     .put(BASE_URL + tag, typeof reqBody !== null && reqBody, {
//       headers: isHeader ? headers : {},
//     })
//     .then((data) => {
//       if (data.status === 200) {
//         return data;
//       } else {
//         return data;
//       }
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

export const Api = {
    fundsReturn: (key) => GetApi(`/${key}/return`),
    positionExposureByAssetClassType: (key) => GetApi(`/${key}/position-exposure/ASSET_CLASS_TYPE`),
    positionExposureByAssetTypeDetails: (key) => GetApi(`/${key}/position-exposure/ASSET_TYPE_DETAIL`),
    performanceExcel: (key) => GetApi(`/${key}/performance/excel`),
    fundParticularPDF: (key) => GetApi(`/${key}/fundParticular`),
    fundsDetails: (key) => GetApi(`/${key}/detail`),
    dateWiseCumulativeReturn: (key) => GetApi(`/${key}/dateWiseCumulativeReturn`),
    performanceAttribute: (key) => GetApi(`/${key}/performance/attribute`),
};
